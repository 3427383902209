import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionTitleText,
  AccordionTrigger,
  Box,
  Button,
  ButtonText,
  Center,
  CloseIcon,
  Heading,
  HStack,
  Icon,
  Input,
  InputField,
  Pressable,
  ScrollView,
  Text,
  Toast,
  ToastTitle,
  useToast,
  View,
  VStack,
} from '@gluestack-ui/themed';
import { useMutation } from '@tanstack/react-query';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react-native';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import { PrimaryButton } from '../components/buttons/PrimaryButton';
import { SecundaryButton } from '../components/buttons/SecundaryButton';
import { Carousel } from '../components/camera/Carousel';
import { Title } from '../components/Titles/Title';
import { TranslationContext } from '../contexts/translations/Translation';
import { UserContext } from '../contexts/User';
import type { AnalysisDTO } from '../generated/client-api-client';
import { AnalysisService } from '../generated/client-api-client';
import type { IAnalysisFormPageProps } from '../routes/Routes';
import { Routes } from '../routes/Routes';
import { styles } from '../theme/styles';
import { uploadToS3 } from '../utils/AwsS3Utils';

export interface IFormItemProps {
  label: string;
  propertyName: string;
  value: string | undefined;
  isDisabled?: boolean;
}

export interface IFormItemObjectProps {
  label: string;
  propertyName: string;
  value: Record<string, any>;
}

export const EditAnalysisFormPage: FC<IAnalysisFormPageProps> = (props: IAnalysisFormPageProps) => {
  const { navigation } = props;
  const { analysisList } = props.route.params;
  const [currentAnalysisIndex, setCurrentAnalysisIndex] = useState<number>(0);
  const [formData, setFormData] = useState<Partial<AnalysisDTO>>(analysisList[0] ?? {});
  const [formList, setFormList] = useState<Partial<AnalysisDTO>[]>(analysisList ?? []);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const { i18n } = useContext(TranslationContext);
  const user = useContext(UserContext);

  const { close, show } = useToast();

  const showStartAnalysisJobErrorToast = () => {
    show({
      id: 'startAnalysisJobError',
      placement: 'bottom left',
      duration: 10_000,
      render: ({ id }) => (
        <Toast action="error" variant="accent" alignItems="center" gap={10}>
          <ToastTitle>{i18n?.t('page.analysis-details.toast.start-analysis-job-error')}</ToastTitle>
          <Pressable onPress={() => close(id)}>
            <Icon as={CloseIcon} />
          </Pressable>
        </Toast>
      ),
    });
  };

  const { mutateAsync: saveAnalysis, isPending: isSavingAnalysis } = useMutation({
    mutationFn: async (data: Partial<AnalysisDTO>) => {
      if (!data.id) {
        const response = await AnalysisService.analysisControllerCreate(user!.currentWorkspace!.id!, {
          name: formData?.name ?? '',
          sampleId: formData?.sampleId ?? '',
          receptionDate: new Date(formData?.receptionDate ?? new Date()).toISOString(),
          properties: formData.properties ?? {},
        });

        await uploadToS3(formData.imageUrl!, response.uploadURL);
        try {
          await AnalysisService.analysisControllerStartAnalysis(user!.currentWorkspace!.id!, response.id!);
        } catch (error) {
          showStartAnalysisJobErrorToast();
        }
        return response.id;
      }
      if (
        formData.name === formList[currentAnalysisIndex].name &&
        JSON.stringify(formData.properties) === JSON.stringify(formList[currentAnalysisIndex].properties) &&
        formData.receptionDate === formList[currentAnalysisIndex].receptionDate &&
        formData.imageUrl === formList[currentAnalysisIndex].imageUrl &&
        formData.sampleId === formList[currentAnalysisIndex].sampleId
      ) {
        return data.id;
      }
      await AnalysisService.analysisControllerUpdate(user!.currentWorkspace!.id!, data.id ?? '', data);
      return data.id;
    },
  });

  useEffect(() => {
    if ((formData?.name?.length ?? 0) > 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [formData?.name, formData?.properties, formData?.receptionDate]);

  useEffect(() => {
    setFormData(formList[currentAnalysisIndex]);
  }, [currentAnalysisIndex]);

  const FormItem = ({ label, propertyName, value, isDisabled }: IFormItemProps) => (
    <HStack>
      <Text style={{ flex: 1, marginVertical: 10 }} alignSelf="center">
        {label}
      </Text>
      <Input style={{ flex: 3, margin: 10 }} isDisabled={isDisabled}>
        <InputField defaultValue={value} onBlur={(event: any) => setFormData({ ...formData, [propertyName]: event?.target?.value ?? value })} />
      </Input>
    </HStack>
  );

  const FormPropertiesItem = ({ label, propertyName, value, isDisabled }: IFormItemProps) => (
    <HStack>
      <Text style={{ flex: 1, marginVertical: 10 }} alignSelf="center">
        {label}
      </Text>
      <Input style={{ flex: 3, margin: 10 }} isDisabled={isDisabled}>
        <InputField defaultValue={value} onBlur={(event: any) => setFormData({ ...formData, properties: { ...formData.properties, [propertyName]: event?.target?.value ?? value } })} />
      </Input>
    </HStack>
  );

  const FormItemObject = ({ label, propertyName, value }: IFormItemObjectProps) => (
    <VStack>
      <Accordion size="md" variant="unfilled" isCollapsible={true} isDisabled={false}>
        <AccordionItem value={label}>
          <AccordionHeader>
            <AccordionTrigger>
              {({ isExpanded }) => {
                return (
                  <>
                    <AccordionTitleText>{label}</AccordionTitleText>
                    {isExpanded ? <AccordionIcon as={ChevronUpIcon} ml="$3" /> : <AccordionIcon as={ChevronDownIcon} ml="$3" />}
                  </>
                );
              }}
            </AccordionTrigger>
          </AccordionHeader>
          <AccordionContent>
            {Object.entries(value ?? {}).map(([key, nestedValue]) => (
              <HStack key={key}>
                <Text style={{ flex: 1, marginVertical: 10 }}>{key}</Text>
                <Input style={{ flex: 3, margin: 10 }}>
                  <InputField defaultValue={nestedValue} onBlur={(event: any) => setFormData({ ...formData, [propertyName]: { ...value, [key]: event?.target?.value ?? nestedValue } })} />
                </Input>
              </HStack>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </VStack>
  );

  return (
    <View style={styles.layout}>
      <VStack>
        <HStack justifyContent="space-between">
          <Title title={i18n?.t('page.analysis-details.title')} />
          <Center>
            <Box style={{ backgroundColor: 'white', borderRadius: 10, padding: 10, shadowColor: '#171717', shadowOffset: { width: -2, height: 4 }, shadowOpacity: 0.2, shadowRadius: 3 }}>
              <Text>
                {new Date(formData.createdAt!).toLocaleDateString(i18n.locale)} | {new Date(formData.createdAt!).toLocaleTimeString(i18n.locale)}
              </Text>
            </Box>
          </Center>
        </HStack>
        <HStack style={{ marginVertical: 10, gap: 30, marginTop: 50 }}>
          <Box
            style={{
              aspectRatio: '1/1',
              borderRadius: 10,
              maxHeight: 450,
              maxWidth: 450,
              minHeight: 150,
              minWidth: 150,
              height: '100%',
              width: '100%',
              flexGrow: 1,
            }}
          >
            <Carousel
              disableControls={true}
              overrideCurrentPhotoIndex={currentAnalysisIndex}
              onPhotoChange={setCurrentAnalysisIndex}
              photoUriList={analysisList.map((analysis) => analysis.imageUrl!)}
            />
          </Box>

          <VStack
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
              flex: 1.5,
              marginLeft: 50,
              paddingHorizontal: 50,
              paddingVertical: 20,
              shadowColor: '#171717',
              shadowOffset: { width: -2, height: 4 },
              shadowOpacity: 0.2,
              shadowRadius: 3,
            }}
          >
            <ScrollView showsVerticalScrollIndicator={false}>
              <VStack>
                <Heading size="md">{i18n?.t('page.new-analysis.form.analysis-information-section-label')}</Heading>
                <FormItem label={i18n?.t('page.new-analysis.form.analysis-id-label')} propertyName="id" value={formData?.id} isDisabled={true} />
                <FormItem label={`${i18n?.t('page.new-analysis.form.analysis-name-label')}*`} propertyName="name" value={formData?.name} isDisabled={!!formData?.id} />
              </VStack>

              <VStack>
                <Heading size="md">{i18n?.t('page.new-analysis.form.sample-information-section-label')}</Heading>
                <FormPropertiesItem label={i18n?.t('page.new-analysis.form.sample-id-label')} propertyName="sampleId" value={formData?.properties?.sampleId} isDisabled={!!formData?.id} />
                <FormPropertiesItem
                  label={i18n?.t('page.new-analysis.form.sample-received-date-label')}
                  propertyName="receptionDate"
                  value={formData?.properties?.receptionDate ? new Date(formData.properties.receptionDate).toLocaleDateString(i18n.locale) : 'mm/dd/yyyy'}
                />
                <FormPropertiesItem label={i18n?.t('page.new-analysis.form.sample-received-laboratory-label')} propertyName="laboratoryId" value={formData?.properties?.laboratoryId ?? ''} />
              </VStack>
            </ScrollView>
            <HStack justifyContent="space-evenly" flexWrap="wrap">
              <SecundaryButton
                content={i18n?.t('common.cancel')}
                style={{ margin: 10 }}
                onPress={async () => {
                  navigation.goBack();
                }}
              />
              <SecundaryButton
                content={i18n.t('page.new-analysis.form.previous-button-label')}
                style={{ margin: 10 }}
                isDisable={currentAnalysisIndex === 0}
                onPress={async () => {
                  const updatedFormList = formList.map((analysis, index) => {
                    if (index === currentAnalysisIndex) {
                      return { ...formData };
                    }
                    return analysis;
                  });
                  const previousIndex = (currentAnalysisIndex - 1 + formList.length) % formList.length;
                  setFormList(updatedFormList);
                  setCurrentAnalysisIndex(previousIndex);
                }}
              />
              <PrimaryButton
                content={currentAnalysisIndex === formList.length - 1 ? i18n.t('page.new-analysis.form.save-form-button-label') : i18n.t('page.new-analysis.form.next-button-label')}
                onPress={async () => {
                  const id = await saveAnalysis(formData);
                  const updatedFormList = formList.map((analysis, index) => {
                    if (index === currentAnalysisIndex) {
                      return { ...formData, id };
                    }
                    return analysis;
                  });
                  if (currentAnalysisIndex === formList.length - 1) {
                    if (formList.length === 1) {
                      return navigation.navigate(Routes.ANALYSIS, { analysisId: id });
                    }
                    return navigation.navigate(Routes.ANALYSIS_LIST, { watchAnalysisIds: updatedFormList.map((analysis) => analysis.id!) });
                  }

                  const nextIndex = currentAnalysisIndex + (1 % formList.length);
                  setFormList(updatedFormList);
                  setCurrentAnalysisIndex(nextIndex);
                }}
                style={{ margin: 10 }}
                isDisable={!canSubmit}
                isLoading={isSavingAnalysis}
              />
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </View>
  );
};
